import React, { useState, useEffect } from "react"

//' Still need to handle letters with accents like séance

const inputType = {
  ENTER: 'Enter',
  BACKSPACE: 'Backspace'
}

export default (props) => {

  const [state, setState] = useState( () => {
    return {
      actual: props.actual,
      inputted: props.inputted
    }
  })

  //*Really important. Each time a displayChar updates, the commandref must be refreshed otherwise papa will have access to a stale state in his commandrefs
  useEffect(() => {
    if (props.commandRef !== undefined && props.commandRef.current !== null) {
      props.commandRef.current = handleCommand
    }
  })

  if (props.commandRef !== undefined && props.commandRef.current === null) props.commandRef.current = handleCommand

  //This function is meant to mimic the functional relationship of OOD. The component which owns this component may pass a commandRef to props to tap into this function similar to how an object's owner calls functions on said object in OOD. The command argument is a key value object. The key is like the function's name. The value is like the function's arguments.
  function handleCommand(command) {

    const commandKey = Object.keys(command)[0]

    if (commandKey === 'check') {
      const inputToCheck = command['check']
      // if (inputToCheck === state.actual) return true
      if (isEquivalent(state.actual, inputToCheck)) return true
      return false
    }
    else if (commandKey === 'get') {
      return Object.assign({}, state)
    }
    else if (commandKey === 'set') {
      const commandedState = command['set']
      let innerStateEntries = Object.entries(state)
      let commandedStateEntries = Object.entries(commandedState)
      let updatedValues = {}

      for (let [commandKey, commandValue] of commandedStateEntries) {
        let foundInnerStateEntry = innerStateEntries.find(innerStateEntry =>{
          return innerStateEntry[0] === commandKey
        })

        if (foundInnerStateEntry !== undefined){
          const innerStateValue = foundInnerStateEntry[1]
          if (innerStateValue === commandValue) console.log(props.dataKey + " command of '" + commandKey + "' has value: " + commandValue + " that already matched inner state")

          else updatedValues[commandKey] = commandValue
        }
        else {
          console.log(props.dataKey + " command of '" + commandKey + "' not recognized")
        }
      }

      if (Object.keys(updatedValues).length > 0){
        setState(prevState => {return{...prevState, ...updatedValues}})
      }  
    }
  }
  
  let match = false;
  let falseSpace = false;
  //empty falseInput represents either that the user got it right, or that the user hasn't yet arrived at this character yet, or that the user backspaced and thus removed a falseInput
  let falseInput = ''
  if (state.inputted.length) {
    if (isEquivalent(state.actual, state.inputted)) {
      match = true
    }
    //possibly a backspace
    else if (state.inputted !== inputType.BACKSPACE) {
      //false input by this point 
      falseInput = state.inputted
      if (state.inputted === ' ') {
        falseSpace = true;
      }
    }
  }

  // ^ displayChar return

  //special newline logic
  if (state.actual === inputType.ENTER) {
    //a false space occured at the newline. This logic is needed for it to work on Safari, it needs both the ' ' and the &nbsp;.
    if (falseSpace) {
      return (
        <span id={props.infoKey} ref={props.handleRef} className={'displayChar newLine'}>
          {' '}
          <span className={'incorrect falseSpace'}>&nbsp;</span>
          <br />
        </span>
      )
    }
    //normal newline return logic for no chars or typical chars
    return (
      <span id={props.infoKey} ref={props.handleRef} className={'displayChar newLine'}>
        {falseInput.length === 0 ? ' ' : ''}
        <span className='incorrect'>{falseInput}
        </span>
        <br />
      </span>
    )
  }
  //normal displayChar logic
  return (
    <span id={props.infoKey} ref={props.handleRef} className={('displayChar ' + (match === true ? 'correct' : ''))} >
      {state.actual}
      <span className={'incorrect' + (falseSpace === true ? ' falseSpace' : '')}>{falseInput}</span>
        {props.verseNumberDisplay.length > 0 && <span className='verseContainer'>{props.verseNumberDisplay}</span>}
    </span>
  )
}

function isEquivalent(actual, inputted) {
  let match = false

  if (actual === inputted) {
    match = true
  }
  else if ((inputted === '"' || inputted === '“') && (actual === '"' || actual === '“')) {
    match = true
  }
  else if ((inputted === '"' || inputted === '”') && (actual === '"' || actual === '”') ) {
    match = true
  }
  else if ((inputted === "'" || inputted === '‘') && (actual === "'" || actual === '‘')) {
    match = true
  }
  else if ((inputted === "'" || inputted === '’') && (actual === "'" || actual === '’')) {
    match = true
  }
  else if ((inputted === '-' || inputted === '–' || inputted === '—') && ((actual === '-' || actual === '–') || actual === '—')) {
    match = true
  }

  return match
}