const themes = {
  textBgColor: '#ece8cd', //nearly white yellow
  bgColor: '#3e3128', //dark brown
  bgColorDark: '#372d26', //darker brown
  borderColor: '#fed959', //golden yellow
  textColor: '#ecc900', //saturated golden yellow
  buttonColor: '#513724', //more saturated brown
  bannerColorMain: '#8f1c1c', //red banner
  bannerColor2: '#1d1d5c' //blue accents
}
Object.freeze(themes)

export {themes}