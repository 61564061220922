import React, { useState, useRef, useEffect, createRef, useCallback } from "react"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"

import DisplayChar from '../components/DisplayChar.js'
import BookChooser from '../components/BookChooser.js'
import ModalResults, { ModalIncompatible } from '../components/ModalResults.js'

import {themes} from '../Utility/Constants'

const inputType = {
  ENTER: 'Enter',
  BACKSPACE: 'Backspace'
}

const modes = {
  CHOOSING: 'choosing',
  LOADING: 'loading',
  TYPING: 'typing'
}

const IndexPage = () => {

  const [typingMode, setTypingMode] = useState(modes.CHOOSING)

  const [modalResults, setModalResults] = useState({
    active: false,
    totalChar: 0,
    errors: 0,
    accuracy: 0.00,
    wpm: 0
  })

  const [modalIncompatible, setModalIncompatible] = useState(false)

  const [choiceState, setChoiceState] = useState({version: 'KJV', book:'Genesis', startCh:1,startVerse:1,endVerse:31, stats:true, uniqueKeyBase:'start'})

  //The choiceCallback is initiated when the begin button is pressed in BookChooser. BookChooser calls this function passing along its state as the action so that the index can set its state accordingly and initiate typingMode.
  const choiceCallback = useCallback(
    (action) => {
      setChoiceState({ ...action, uniqueKeyBase: new Date().getTime().toString() })
      setTypingMode(modes.LOADING)
    },
    []
  )

  //the cancelCallback is initiated when the cancel button is pressed in the BookChooser.
  const cancelCallback = useCallback(
    () => {
      setTypingMode(modes.CHOOSING)
    }
  )

  const resultsExitCallback = useCallback(
    () => {
      setModalResults({
        ...modalResults,
        active: false
      })
      setTypingMode(modes.CHOOSING)
    }
  )

  const resultsRedoCallback = useCallback(
    () => {
      setChoiceState({
        ...choiceState,
        uniqueKeyBase: new Date().getTime().toString()
      })
      setModalResults({
        ...modalResults,
        active: false
      })
      setTypingMode(modes.LOADING)
    }
  )

  const incompatibleExitCallback = useCallback(
    () => {
      setModalIncompatible(false)
    }
  )

  const bookChooserCommandRef = useRef()
  //holds a bunch of displayChar components which hold both the static value and the input (if there is any)
  const displayCharComponentsArray = useRef([])
  //Refs to each displayChar's command interface, allowing you to send commands to them to update their state or check for incorrect values
  const commandRefsArray = useRef([])
  //These are true forwarded refs that reference the actual html elements of the display chars.
  const displayCharHandlesArray = useRef([])

  const timer = useRef()
  const isTiming = useRef(false)

  const containerDivYLimit = useRef()
  const jamesDivRef = useRef()
  const jsonTextPos = useRef(0)
  const transformAmount = useRef(0)

  const windowWidth = useRef(null)
  const windowHeight = useRef(null)
  const baseFontSize = useRef(null)

  //get browser font size
  useEffect(() => {
    baseFontSize.current = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'), 10)
  },[])

  //window resize handler
  useEffect(() => {
    windowWidth.current = window.innerWidth
    windowHeight.current = window.innerHeight

    let theWindow = window

    if (typeof window.visualViewport !== 'undefined') {
      windowHeight.current = window.visualViewport.height
      theWindow = window.visualViewport
    }

    const handleResize = (e) => {
      const newWidth = window.innerWidth
      let newHeight = window.innerHeight

      //Firefox requires the user to enable visualViewport flag, so I can only use visualViewport if it's enabled. (only bad case is firefox on mobile will not benefit)
      if (typeof window.visualViewport !== 'undefined') {
        newHeight = window.visualViewport.height
      }

      //update transform bounds no matter what because it can change if the resize occured due to the user changing the zoom factor
      if (newHeight !== windowHeight.current) {
        //have middle-point be between 1/2 and 2/3 of viewport (aka 7/12)
        containerDivYLimit.current = 7 * newHeight / 12;

        //Edge case: use entirely different middle-point when virtual keyboards take up a huge chunk of the vertical screen. If jamesdiv starts at >40% of the total visual height then the middle point is now based on the middle of james div, rather than the latter half of the screen.
        const jamesStartY = jamesDivRef.current.getBoundingClientRect().y
        const jamesStartYPercent = jamesStartY / newHeight
        if (jamesStartYPercent > 0.4) {
          const middleOfJames = (newHeight - jamesStartY) / 2
          containerDivYLimit.current = jamesStartY + middleOfJames
        }

        //a taller window means the transform may have to move down to put the cursor back at the center
        if (newHeight > windowHeight.current) {
          handleTransform(false)
        }
        //a shorter window means the transform may have to move up to put the cursor back at cetner
        else { 
          handleTransform(true)
        }

        windowHeight.current = newHeight
      }

      //Warning: when a "restore up" occurs, two resizes are recorded. The first resize has the windowidth stay exactly the same for some reason. The second has the new width.
      if (newWidth > windowWidth.current) {
        handleTransform(false)
      }
      else if (newWidth < windowWidth.current) {
        handleTransform(true)
      }

      windowWidth.current = newWidth
    }

    theWindow.addEventListener("resize", handleResize)

    return (() => {
      theWindow.removeEventListener('resize', handleResize)
    })
    //handle transform depends on the typing mode, so this has to update based on typing mode
  }, [typingMode])
  
  useEffect(() => {
    if (typingMode === modes.TYPING) {
      document.getElementById('jamesdiv').focus()
    }
  }, [typingMode])

  //^ fetch book
  useEffect(() => {
    if (choiceState.uniqueKeyBase === 'start') return

    //file names are same as book names except no spaces
    let fetchBook = choiceState.book.replace(/\s/g, '')
    displayCharComponentsArray.current = []
    commandRefsArray.current = []
    displayCharHandlesArray.current = []
    jsonTextPos.current = 0
    transformAmount.current = 0
    
    fetch( ("/" + choiceState.version + "/" + fetchBook + ".json"), { mode: "no-cors" })
    .then((res) => res.text())
    .then((text) => {
      
      switch (choiceState.version) {
        case 'KJV':
          prepareKJV(text, choiceState, displayCharComponentsArray, commandRefsArray, displayCharHandlesArray)
          break;
        case 'WEB':
          prepareWEB(text, choiceState, displayCharComponentsArray, commandRefsArray, displayCharHandlesArray)
          break;
      }

      setTypingMode(modes.TYPING)
    })

    //transform bounds
    containerDivYLimit.current = 7 * window.innerHeight / 12;
    //reset transform to 0
    jamesDivRef.current.style.transform = 'translateY(' + 0 + 'px)'
    
    isTiming.current = false

  }, [choiceState])

  function setCaret() {
    var range = document.createRange()
    var sel = window.getSelection()
    
    //handle finished case where jsonTextPos would cause out of bounds error
    let properPosition = jsonTextPos.current
    if (jsonTextPos.current === displayCharHandlesArray.current.length) {
      properPosition -= 1
    }
    // range.setStart(jamesDivRef.current.childNodes[properPosition], 0)

    range.setStart(displayCharHandlesArray.current[properPosition].current, 0)
    range.collapse(true)
    
    sel.removeAllRanges()
    sel.addRange(range)
  }

  function handleTransform(transformUp) {
    if (typingMode !== modes.TYPING) return

    let properPosition = jsonTextPos.current

    //handle "finished" case where jsonTextPos would cause out of bounds error. Use a position of one less in this case.
    if (jsonTextPos.current === displayCharHandlesArray.current.length) {
      properPosition -= 1
    }

    const futureYPosition = displayCharHandlesArray.current[properPosition].current.getBoundingClientRect().y
    const fontScaling = 1.35
    const lineHeight = 1.5
    const charHeight = baseFontSize.current * fontScaling * lineHeight
    const travelDistance = Math.abs(futureYPosition - containerDivYLimit.current)

    //Scrolling down (transforming upwards). charHeight/2 added to prevent a micro transform due to possible tiny initial offset transform from first time caret passes the limit
    if (transformUp && travelDistance > charHeight/2 && futureYPosition > containerDivYLimit.current) {
      transformAmount.current -= travelDistance
      jamesDivRef.current.style.transform = 'translateY(' + transformAmount.current + 'px)'
    }
    //Scrolling up (tranforming downwards for backspace)
    else if (!transformUp && futureYPosition < (containerDivYLimit.current - 1) && transformAmount.current < 0) {
      transformAmount.current += travelDistance
      //fix an overscroll so that it can't scroll past starting line. Also if there's just a microscroll left to get to start, just advance to start
      if (transformAmount.current > 0 || Math.abs(transformAmount.current) <= charHeight/2) transformAmount.current = 0

      jamesDivRef.current.style.transform = 'translateY(' + transformAmount.current + 'px)'
    }

    //Technique for incremental (only made sense when lineheight was same across text and newlines)
    // const totalTravel = Math.ceil(travelDistance / charHeight) * charHeight
    // if (transformUp && futureYPosition > containerDivYLimit.current) {
    //   transformAmount.current -= totalTravel
    //   jamesDivRef.current.style.transform = 'translateY(' + transformAmount.current + 'px)'
    // }
    // else if (!transformUp && futureYPosition < (containerDivYLimit.current - charHeight) && transformAmount.current < 0) {
    //   transformAmount.current += totalTravel
    //   if (transformAmount.current > 0) transformAmount.current = 0
    //   jamesDivRef.current.style.transform = 'translateY(' + transformAmount.current + 'px)'
    // }
  }

  function handleKeyDown(e) {
    e.preventDefault()

    //bailout if user is trying to type more stuff after finishing
    if (jsonTextPos.current === displayCharHandlesArray.current.length) return

    let transformUp = true
    //backspace logic
    if (e.key === inputType.BACKSPACE) {
      if (jsonTextPos.current === 0) return

      transformUp = false
      jsonTextPos.current -= 1
      let commander = commandRefsArray.current[jsonTextPos.current].current
      commander({ set: { inputted: e.key } })
    }
    else {
      let commander = commandRefsArray.current[jsonTextPos.current].current

      //virtual keyboard with unrecognized input (pretty much all Android). Bailout of typing and present the bailout information modal. Otherwise input will just push aside the displayChars and nothing will work.
      if (e.key === 'Unidentified') {
        document.getElementById('jamesdiv').contentEditable = 'false'
        setModalIncompatible(true)
        return
      }
      //do nothing for shift
      if (e.key === "Shift") return
      //do nothing for an improper enter
      if (e.key === inputType.ENTER && !commander({ check: inputType.ENTER })) return
      
      //Reject command keys like tab, ctrl, arrows, etc. Their character length is more than 1 so it makes this easy. Enter is proper by this point.
      if (e.key !== inputType.ENTER && e.key.length > 1) return

      //at this point we can only have a normal keypress, so I can start the timer if it hasn't been started
      if (!isTiming.current) {
        timer.current = new Date()
        isTiming.current = true;
      }

      if (jsonTextPos.current === 0) {
        bookChooserCommandRef.current({handleTypingStarted:''})
      }
      
      commander({ set: { inputted: e.key } })
      jsonTextPos.current += 1

      //Handle finish logic (done whether stats are toggled on or off. The modal decides whether to show final results based off stats toggle)
      if (commandRefsArray.current.length === jsonTextPos.current) {
        //convert milliseconds to seconds
        const elapsedTime = (new Date() - timer.current) / 1000 
        //These two unused, but might be used in future display
        const minutes = Math.floor(Math.floor(elapsedTime) / 60)
        const seconds = elapsedTime - 60 * minutes

        let totalChar = 0
        let incorrectChar = 0

        commandRefsArray.current.forEach((commandRef, index) => {
          const displayCharState = commandRef.current({ get: '' })
          //every character except the final character
          if (index !== commandRefsArray.current.length - 1) {
            if (!commandRef.current({ check: displayCharState.inputted })) {
              incorrectChar += 1
            }
          }
          else { //the final character
            if (!commandRef.current({ check: e.key })) {
              incorrectChar += 1
            }
          }

          totalChar += 1
        })

        const totalWords = totalChar / 5
        const unroundedMinutes = elapsedTime / 60
        const wpm = Math.round((totalWords - incorrectChar) / unroundedMinutes)

        let accuracy = (((totalChar - incorrectChar) / totalChar) * 100).toFixed(2)
        if (incorrectChar > 0 && accuracy === 100.00) accuracy = 99.99

        accuracy = accuracy

        setModalResults({
          active: true,
          totalChar: totalChar,
          errors: incorrectChar,
          accuracy: accuracy,
          wpm: wpm
        })
      }
    }

    setCaret()
    handleTransform(transformUp)
  }

  function handleClick(e) {
    e.preventDefault()
    //do nothing if user just finished
    if (jsonTextPos.current === displayCharHandlesArray.current.length) return

    setCaret()
  }

  //this only gets fired if using a physical keyboard on a phone I think. This will prevent the screw-ups where the phone puts additional text into the div.
  function handleBeforeInput(e) {
    e.preventDefault()
  }
  // ^ return
  return (
    <div css={getCSS(themes)}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Typing Temple</title>
        <meta name='description' content='Practice typing by typing the bible!'/>
        <meta name='viewport' content="width=device-width, initial-scale=1"></meta>
        <link rel="canonical" href="https://typingtemple.com" />
      <html lang="en" />
      </Helmet>
      <BookChooser key='bookchooser'
        typingMode={typingMode}
        commandRef={bookChooserCommandRef}
        choiceCallback={choiceCallback}
        cancelCallback={cancelCallback}
      />

      <div id='decal-container'>
        <div id="text-container" key='text-container'>
          <div id="jamesdiv" ref={jamesDivRef}
            contentEditable={typingMode === modes.TYPING ? true : false}
            className={typingMode === modes.TYPING  ? 'visible' : 'invisible'} suppressContentEditableWarning={true}
            spellCheck="false"
            onKeyDown={handleKeyDown}
            onBeforeInput={handleBeforeInput}
            onClick={handleClick}
          >
            {displayCharComponentsArray.current}
          </div>
        </div>
        <div className='decal bar' />
        <div className='decal sidebar rightbar' />
        <div className='decal sidebar leftbar' />
        <div className='decal right square' />
        <div className='decal left square' />
        <div className='decal bottom' />
      </div>
      {modalResults.active &&
        <ModalResults
        stats={choiceState.stats}
        totalChar={modalResults.totalChar}
        errors={modalResults.errors}
        accuracy={modalResults.accuracy}
        wpm={modalResults.wpm}
        resultsExitCallback={resultsExitCallback}
        resultsRedoCallback={resultsRedoCallback}
        />
      }
      {modalIncompatible && 
        <ModalIncompatible incompatibleExitCallback={incompatibleExitCallback}/>
      }
    </div>
  )
}

export default IndexPage

//^ Typing Preparation Logic
function prepareKJV(text, choiceState, displayCharComponentsArray, commandRefsArray, displayCharHandlesArray) {
  let positionCounter = 0;
  let jsonObject = JSON.parse(text)
  let finished = false
  //go through every verse
  for (let j = choiceState.startVerse - 1; j < choiceState.endVerse; j++){
    let verseString = jsonObject.chapters[choiceState.startCh - 1].verses[j].text

    if (j === choiceState.endVerse - 1) finished = true
    
    //go through every character of the verse
    let beginNewWord = true
    let charHolder = []
    for (let k = 0; k < verseString.length; k++) {
      let commandRef = createRef()
      let handleRef = createRef()
      commandRefsArray.current.push(commandRef)
      displayCharHandlesArray.current.push(handleRef)
      let verseNumberDisplay = ''
      if (k === 0) verseNumberDisplay = '' + (j + 1)

      let currentChar = verseString[k]
      let displayChar = <DisplayChar
        actual={currentChar}
        inputted=''
        commandRef={commandRef}
        handleRef={handleRef}
        key={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
        infoKey={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
        verseNumberDisplay={verseNumberDisplay}
      />

      //starting a new word
      if (beginNewWord) {
        charHolder = []
        charHolder.push(displayChar)
        beginNewWord = false
      }
      //reached a space
      else if (currentChar === ' ') {
        let wordSpanComponent = <span className='word-holder' key={positionCounter + '_WordHolder_' + choiceState.uniqueKeyBase}>
          {charHolder}
        </span>
        displayCharComponentsArray.current.push(wordSpanComponent)
        displayCharComponentsArray.current.push(displayChar)
        beginNewWord = true
      }
      //reached very last char of a verse
      else if (k === (verseString.length - 1)) {
        charHolder.push(displayChar)
        //create and push the span containing the array of displayChars before the current 'space' displayChar
        let wordSpanComponent = <span className='word-holder' key={positionCounter + '_WordHolder_' + choiceState.uniqueKeyBase}>
          {charHolder}
        </span>
        displayCharComponentsArray.current.push(wordSpanComponent)
        beginNewWord = true
      }
      //in the middle of a word
      else {
        charHolder.push(displayChar)  
      }

      positionCounter += 1
    }
  
    //at the end of the verse, handle return character unless it was the final verse
    if (!finished) {
      let commandRef = createRef()
      let handleRef = createRef()
      commandRefsArray.current.push(commandRef)
      displayCharHandlesArray.current.push(handleRef)
      let displayChar = <DisplayChar
        actual={inputType.ENTER}
        inputted=''
        commandRef={commandRef}
        handleRef={handleRef}
        key={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
        infoKey={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
      />
      displayCharComponentsArray.current.push(displayChar)
      positionCounter += 1
    }
  }
}

//Warning: This logic assumes every single verse ends in a blank space. The ending verse has its blank space popped off. So if a bug involves a verse ending with a meaningful character, that could get popped off if its the last verse.
function prepareWEB(text, choiceState, displayCharComponentsArray, commandRefsArray, displayCharHandlesArray) {
  let positionCounter = 0;
  let jsonArray = JSON.parse(text)
  let startingVerseIndex = 0
  let newLineNeeded = false

  //loop to find the starting index of the first relevant verse
  for (let i = 0; i < jsonArray.length; i++){
    let jsonObject = jsonArray[i]
    if (jsonObject.type === 'paragraph text' || jsonObject.type === 'line text') {
      if (jsonObject.chapterNumber === choiceState.startCh && jsonObject.verseNumber === choiceState.startVerse) {
        startingVerseIndex = i
        break;
      }
    }
  }

  //Now we can properly loop from the first relevant verse
  for (let j = startingVerseIndex; j < jsonArray.length; j++){
    let jsonObject = jsonArray[j]

    //paragraph/line objects that must be checked
    if (jsonObject.type === 'paragraph text' || jsonObject.type === 'line text') {

      //still in relevant chapter
      if (jsonObject.chapterNumber === choiceState.startCh) {
        //the verse is now past the endVerse so we can break out. But we must remove the final uneccessary space.
        if (jsonObject.verseNumber > choiceState.endVerse) {
          commandRefsArray.current.pop()
          displayCharHandlesArray.current.pop()
          displayCharComponentsArray.current.pop()
          break;
        }

        //The verse is inbounds and to be included

        //create return display char if one is needed (there was a prior paragraph/stanza start/end object). Also the ending space on the prior verse must be removed.
        if (newLineNeeded) {
          commandRefsArray.current.pop()
          displayCharHandlesArray.current.pop()
          displayCharComponentsArray.current.pop()

          let commandRef = createRef()
          let handleRef = createRef()
          commandRefsArray.current.push(commandRef)
          displayCharHandlesArray.current.push(handleRef)
          let displayChar = <DisplayChar
            actual={inputType.ENTER}
            inputted=''
            commandRef={commandRef}
            handleRef={handleRef}
            key={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
            infoKey={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
          />
          displayCharComponentsArray.current.push(displayChar)
          positionCounter += 1
          newLineNeeded = false
        }

        //go through every character of the verse
        const verseString = jsonObject.value
        let beginNewWord = true
        let charHolder = []
        for (let k = 0; k < verseString.length; k++) {

          let commandRef = createRef()
          let handleRef = createRef()
          commandRefsArray.current.push(commandRef)
          displayCharHandlesArray.current.push(handleRef)

          let verseNumberDisplay = ''
          if (k === 0 && jsonObject.sectionNumber === 1) verseNumberDisplay = "" + jsonObject.verseNumber

          let currentChar = verseString[k]
          let displayChar = <DisplayChar
            actual={currentChar}
            inputted=''
            commandRef={commandRef}
            handleRef={handleRef}
            key={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
            infoKey={positionCounter + '_DisplayChar_' + choiceState.uniqueKeyBase}
            verseNumberDisplay={verseNumberDisplay}
          />
          //starting a new word
          if (beginNewWord) {
            charHolder = []
            charHolder.push(displayChar)
            beginNewWord = false
          }
          //reached a space
          else if (currentChar === ' ') {
            let wordSpanComponent = <span className='word-holder' key={positionCounter + '_WordHolder_' + choiceState.uniqueKeyBase}>
              {charHolder}
            </span>
            displayCharComponentsArray.current.push(wordSpanComponent)
            displayCharComponentsArray.current.push(displayChar)
            beginNewWord = true
          }
          //in the middle of a word
          else {
            charHolder.push(displayChar)  
          }
          
          positionCounter += 1
        }

        //special case that the verse is the very last object in the jsonArray (this should never happen since it should always be paragraph/stanza end object. But just in case ). Just pop the last empty space from the verse we just did. No need to break, since entire loop is done now.
        if (j === jsonArray.length - 1) {
          commandRefsArray.current.pop()
          displayCharHandlesArray.current.pop()
          displayCharComponentsArray.current.pop()
        }
      }
      //chapter should be above target ch by this point so we can safely break out
      else { 
        commandRefsArray.current.pop()
        displayCharHandlesArray.current.pop()
        displayCharComponentsArray.current.pop()
        break;
      }
    }
    //possibly relevant paragraph/stanza objects
    else { 
      //newline is needed on paragraph start/end stanza start/end. It can be ignored on line break. There can be weird things with multiples of these for multiple returns, but I'm just doing one. So just set newLineNeeded to true and handle the single return logic on next pertinent text/line object then set flag back to false.
      if (jsonObject.type === 'paragraph start' || jsonObject.type === 'paragraph end' || jsonObject.type === 'stanza start' || jsonObject.type === 'stanza end') {
        newLineNeeded = true
      }
      
      //special case that jsonObject is very last object in the jsonArray. We're done then so pop the empty space from the end of the prior verse.
      if (j === jsonArray.length - 1) {
        commandRefsArray.current.pop()
        displayCharHandlesArray.current.pop()
        displayCharComponentsArray.current.pop()
      }
    }
  }
}


//^ CSS
const per = '%'
const rem = 'rem'
const px = 'px'

const getCSS = (t) => css`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  padding-bottom: 1.5rem;
  background-color: ${t.bgColor};
  z-index: 0;

  #decal-container {
    width: 90%;
    max-width: 1100px;
    margin: 0 auto;
    position: relative;
    border: 2px solid black;
    padding-top: 1rem;
    padding-bottom: calc(1${rem} + 2${px}); 
    background-color: ${t.textBgColor};
    height: 100%;
    min-height: 0; //this prevents overflow for some reason
  }

  #text-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    background-color: ${t.textBgColor};
  }

  .decal {
    position: absolute;
    background-color: ${t.borderColor};
  }

  .square {
    top: -1.5rem;
    height: 4.5rem;
    width: 4.5rem;
    border: 2px solid black;
    z-index: -1;
    background-image: linear-gradient(155deg, rgba(0,0,0,0), rgba(0,0,0,0.07), rgba(0,0,0,0.3));
  }

  .right {
    right: -1.5rem;
  }

  .left {
    left: -1.5rem;
  }

  .bar {
    top: -0.75rem;
    left: 0;
    height: 1rem;
    width: 100%;
    z-index: -1;
    border: 2px solid black;
  }

  .sidebar {
    top: 0;
    height: 100%;
    width: 1rem;
    z-index: -1;
    border: 2px solid black;
  }

  .rightbar {
    right: -0.75rem;
    background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.0), rgba(0,0,0,0.25));
    box-shadow: 5px 10px 40px black;
  }

  .leftbar {
    left: -0.75rem;
    background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.0), rgba(0,0,0,0.25));
    box-shadow: 5px 10px 40px black;
  }

  .bottom {
    height: 1.5rem;
    height: calc(1.5${rem} + 4${px});
    width: 100%;
    width: calc(100${per} + 3${rem});
    top: 100%;
    top: calc(100${per} - 2${px});
    left: -1.5rem;
    border: 2px solid black;
    background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.15), rgba(0,0,0,0.45));
  }

  #jamesdiv {
    position: relative;
    padding: 0 4rem;
    min-height: 100vh;
    box-sizing: border-box;
    text-align: left;
    background-color: ${t.textBgColor};
    white-space: pre-wrap;
    font-family: "Go-Mono";
    font-size: 1.35rem;
    font-weight: bold;
  }

  #jamesdiv:focus {
    outline: none;
  }

  .visible {
    opacity: 1;
    transition: opacity 2s;
    visibility: visible;
  }

  .invisible {
    opacity: 0;
    visibility: hidden;
    transition: opacity 2s, visibility 2s;
  }

`

