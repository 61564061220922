import React from "react"
import { css } from "@emotion/core"

import {themes} from '../Utility/Constants'

//this is the close svg from Feather, thick styled
const SVG_Exit = () => <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>

const ModalResults = (props) => {
  function handleExit(e) {
    props.resultsExitCallback()
  }

  function handleRedo(e) {
    props.resultsRedoCallback()
  }

  const statsComponentsArray = [
    <div key='char-stats' className='stat-component'>
      <div className='stat-title'>Total Characters Typed: </div>
      <div className='stat-value'>{props.totalChar}</div>
    </div>,
    <div key='error-stats' className='stat-component'>
      <div className='stat-title'>Errors: </div>
      <div className='stat-value'>{props.errors}</div>
    </div>,
    <div key='accuracy-stats' className='stat-component'>
      <div className='stat-title'>Accuracy: </div>
      <div className='stat-value'>{props.accuracy + '%'}</div>
    </div>,
    <div key='wpm-stats' className='stat-component'>
      <div className='stat-title'>Words per Minute (wpm): </div>
      <div className='stat-value'>{props.wpm}</div>
    </div>
  ]

  return (
    <div key='modal-results' css={getCSS(themes)}>
      <div className='modal-display' >
        <button className='modal-exit-button' onClick={handleExit}>
          <SVG_Exit/>
        </button>
        <h1>COMPLETE</h1>
        {props.stats && statsComponentsArray}
        <button id='results-redo-button' onClick={handleRedo}>Redo</button>
      </div>
    </div>
  )
}
export default ModalResults

const ModalIncompatible = (props) => {
  function handleExit(e) {
    props.incompatibleExitCallback()
  }

  return (
    <div key='modal-incompatible' css={getCSS(themes)}>
      <div className='modal-display'>
        <button className='modal-exit-button' onClick={handleExit}>
          <SVG_Exit/>
        </button>
        <p id='incompatible-message'>Unfortunatley your input cannot be recognized in the proper manner for this typing website. This website is designed for receiving input from a physical keyboard and can't work with many of the virtual keyboards on smartphones and tablets.</p>
      </div>
    </div>
  )
}

export { ModalIncompatible }

const per = '%'
const rem = 'rem'
const getCSS = (t) => css`

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
  animation: darken 1s;
  animation-fill-mode: forwards;

  @keyframes darken{
    0%{
      background-color: rgba(0,0,0,0);
    }
    100%{
      background-color: rgba(0,0,0,0.5);
    }
  }

  .modal-display{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${t.bgColorDark}; //darker brown
    color: #ecc900;
    text-shadow: 1px 1px 1px black, 2px 2px 1px black, 3px 3px 1px black;
    border: 4px solid ${t.borderColor};
    border-style: outset;
    font-size: 1.125rem;
  }

  h1 {
    padding: 0 1rem;
    margin: 1rem 0;
  }

  .stat-component{
    display: flex;
    white-space: pre;
    margin: 0.25rem 0;
    padding: 0 1rem;
  }

  .stat-component:first-of-type {
    margin-top: 0.5rem;
  }

  .stat-component:last-of-type {
    margin-bottom: 1.5rem;
  }

  button {
    font-family: 'Times New Roman';
    border-radius: 0.35rem;
    border-width: 5px;
    color: ${t.textColor};
    background-color: ${t.buttonColor};
    border-color: ${t.buttonColor};
    border-style: outset;
    text-shadow: 1px 1px 1px black, 2px 2px 1px black, 3px 3px 1px black;
    font-size: 1.25rem;
  }

  .modal-exit-button {
    align-self: flex-end;
    position: relative;
    //This is supposed to be symmetrical, but an optical illusion from the shadowing means I ought to be it a little assymetrical to appear symmetrical.
    margin: 0.4rem 0.2rem 0 0;
    height: 2rem;
    width: 2rem;
    padding: 0;
  }

  #results-redo-button {
    margin-bottom: 0.75rem;
  }

  svg {
    position: absolute;
    height: 80%;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  button:active {
    border-style: inset;
  }

  button:focus {
    outline: none;
  }

  #incompatible-message {
    padding: 0 1.5rem;
    min-width: 300px;
  }
`